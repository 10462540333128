import React from 'react';

import { Box, DataTable, Heading, Text } from 'grommet';
import { nameify } from 'utils/string';
import { CSVLink } from 'react-csv';
import slugify from 'utils/slugify';

const ramda = require('ramda');

interface OrderListProps {
  event?: namespace.Event;
}
function OrderList({ event: { name, orders, address } }: OrderListProps) {
  const extraHeaders = [];
  const columns = [
    { property: 'id', header: 'ID' },
    {
      property: 'booking_time',
      header: 'Booking Time'
    },
    {
      property: 'tickets',
      header: 'Tickets'
    },
    {
      property: 'first_name',
      header: 'First Name',
      render: (datum) => nameify(datum.first_name)
    },
    {
      property: 'last_name',
      header: 'Last Name',
      render: (datum) => nameify(datum.last_name)
    },
    { property: 'email', header: 'Email' },
    { property: 'phone', header: 'Phone' }
  ];
  if (orders && orders.length > 0 && orders[0].answers) {
    Object.keys(orders[0].answers).forEach((key) => {
      columns.push({
        property: key,
        header: key
      });
      extraHeaders.push(key);
    });
  }
  columns.push({
    property: 'venue',
    header: 'Venue',
    render: (datum) => {
      return datum.venue?.address || address;
    }
  });
  const [select, setSelect] = React.useState([]);
  const [sort, setSort] = React.useState({
    property: 'first_name',
    direction: 'desc'
  });
  if (!orders) return null;
  if (orders.length === 0)
    return (
      <Box pad="large" justify="center" align="center">
        <Text>No bookings have been made</Text>
      </Box>
    );

  return (
    <Box>
      <Box
        pad={{ horizontal: 'large', vertical: 'medium' }}
        direction="row"
        align="center"
        justify="between"
      >
        <Heading level="3" color="purple-800">
          Bookings
        </Heading>
        {orders && (
          <Box
            direction="row"
            background="purple-800"
            round="medium"
            pad="xsmall"
            width="150px"
            justify="center"
            alignSelf="end"
          >
            <CSVLink
              data={orders.map((order) =>
                ramda.pick(
                  [
                    'booking_time',
                    'id',
                    'code',
                    'first_name',
                    'last_name',
                    'email',
                    'phone',
                    ...extraHeaders,
                    'venue',
                    'tickets',
                    'currency',
                    'total'
                  ],
                  order
                )
              )}
              filename={`tutadoo_bookings_${slugify(name)}.csv`}
            >
              Download
            </CSVLink>
          </Box>
        )}
      </Box>
      <Box background="dark-grey-100" height="1px" fill="horizontal" />
      <Box pad={{ vertical: 'small', horizontal: 'medium' }}>
        <DataTable
          columns={columns.map((c) => ({
            ...c,
            search:
              c.property.includes('id') ||
              c.property.includes('name') ||
              c.property === 'email' ||
              c.property === 'phone'
            //   c.property === 'venue'
          }))}
          data={orders}
          step={10}
          sort={sort}
          onSort={setSort}
          resizeable
          paginate
          //   onSelect={setSelect}
          select={select}
        />
      </Box>
    </Box>
  );
}

OrderList.defaultProps = {};

export default OrderList;
