import tw from 'twin.macro';
import { useStateMachine } from 'little-state-machine';
import React, { useEffect, useState } from 'react';
import { TicketApi } from 'services/api/ticket-api';
import { useApi } from 'providers/ApiProvider';
import { Button } from 'components/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { decode } from 'js-base64';
import Col from './Col';
import Form from './Form';
import Row from './Row';
import Text from './Text';
import { CreateTicketAction, clearTicketForm } from '../store/actions/CreateTicketAction';

const TicketCreateWrapper = tw.div`flex pt-0`;

export default function TicketCreate({ event, ticket, onCancel }) {
  const { api } = useApi();
  const { actions, state } = useStateMachine({ CreateTicketAction, clearTicketForm });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { pathname } = useLocation();

  const clearOnCancel = () => {
    actions.clearTicketForm(null);
    onCancel();
  };
  useEffect(() => {
    if (ticket) {
      actions.clearTicketForm(null);
      actions.CreateTicketAction({
        ...ticket,
        mode: 'edit',
        description: ticket.description?.includes('b64:')
          ? decode(ticket.description.split('b64:')[1])
          : ticket.description
      });
    } else {
      actions.CreateTicketAction({
        type: 'free'
      });
    }
  }, []);

  const onSubmit = async (data) => {
    actions.CreateTicketAction(data);
    setLoading(true);
    if (!isAuthenticated) {
      loginWithRedirect({
        redirectUri: `${process.env.GATSBY_AUTH0_CALLBACK}/profile`,
        screen_hint: 'signup'
      });
      return;
    }
    const ticketAPI = new TicketApi(api);
    let result;
    if (!ticket) {
      result = await ticketAPI.createTicketType({ event: event.id, ...data });
    } else {
      result = await ticketAPI.updateTicketType({ id: ticket.id, ...data });
    }
    if (result.kind === 'bad-data') {
      alert('Failed: Please try again');
    } else {
      actions.clearTicketForm(null);
      navigate(`/host/event/dashboard/${event.id}/ticket-types/`, {
        replace: true
      });
    }
    setLoading(false);
    setSuccess(true);
    onCancel();
  };
  return (
    <>
      <TicketCreateWrapper>
        <Col>
          <Row spread>
            <Col>
              <Text label={ticket ? 'Edit Ticket' : 'Create Ticket'} xlarge bold />
              <Text
                label="Let your attendees get a seat to your event by providing a ticket"
                small
              />
            </Col>
            <Button onClick={clearOnCancel} bold large>
              ✗
            </Button>
          </Row>
          <Row spacing="sm" />
          {state?.CreateTicketState?.pages && (
            <Form
              busy={loading}
              onCancel={clearOnCancel}
              state={state.CreateTicketState}
              onSubmit={onSubmit}
              submitLabel="Continue"
              pages={state.CreateTicketState.pages}
            />
          )}
        </Col>
      </TicketCreateWrapper>
    </>
  );
}
