import { ApiResponse } from 'apisauce';
import { encode } from 'js-base64';
import { CreateTicketResult } from '.';
import { Api } from './api';
import { createTicket, createTicketType, updateTicketType } from './api-graphql';
import { getGeneralApiProblem } from './api-problem';

export class TicketApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * Create a tickettype for an event.
   */
  createTicketType(data): Promise<CreateTicketResult> {
    const text = `b64:${encode(data.description.replace(/(\r\n|\n|\r)/gm, ''))}`;
    return this.api.apisauce
      .post('', createTicketType({ ...data, description: text }))
      .then((response) => {
        if (!response.ok) {
          const problem = getGeneralApiProblem(response);
          if (problem) return problem;
        }
        if (response.data.errors) {
          return { kind: 'bad-data', data: response.data.errors };
        }
        const ticket = response.data.data;
        return {
          kind: 'ok',
          ticket
        };
      });
  }

  /**
   * Update a tickettype for an event.
   */
  updateTicketType(data): Promise<CreateTicketResult> {
    const text = `b64:${encode(data.description.replace(/(\r\n|\n|\r)/gm, ''))}`;
    return this.api.apisauce
      .post('', updateTicketType({ ...data, description: text }))
      .then((response) => {
        if (!response.ok) {
          const problem = getGeneralApiProblem(response);
          if (problem) return problem;
        }
        if (response.data.errors) {
          return { kind: 'bad-data', data: response.data.errors };
        }
        const ticket = response.data.data;
        return {
          kind: 'ok',
          ticket
        };
      });
  }

  /**
   * Create a ticket for an event.
   */
  createTicket(data): Promise<CreateTicketResult> {
    return this.api.apisauce.post('', createTicket(data)).then((response) => {
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      if (response.data.errors) {
        return { kind: 'bad-data', data: response.data.errors };
      }
      const ticket = response.data.data;
      return {
        kind: 'ok',
        ticket
      };
    });
  }

  /**
   * Set status of a ticket.
   */
  setStatus(id, status): Promise<CreateTicketResult> {
    return this.api.apisauce.post('', updateTicketType({ id, status })).then((response) => {
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      if (response.data.errors) {
        return { kind: 'bad-data', data: response.data.errors };
      }
      const ticket = response.data.data;
      return {
        kind: 'ok',
        ticket
      };
    });
  }
}
