import Row from 'components/Row';
import Col from 'components/Col';
import Text from 'components/Text';
// import Button from 'components/Button';
import React from 'react';
import tw from 'twin.macro';
import styled, { css } from 'styled-components';
import TicketIcon from 'components/icons/components/Ticket.svg.react';
import { decode } from 'js-base64';
import { currencyToText } from 'utils/money';
import { Box, Tip, Button as GButton, Spinner } from 'grommet';

interface TicketTypeRowProps {
  ticketType: namespace.TicketType;
  loading: boolean;
  onHide: (e) => {};
}

function TicketTypeRow({ ticketType, onEdit, loading, onHide }: TicketTypeRowProps) {
  const description = ticketType.description?.includes('b64:')
    ? decode(ticketType.description.split('b64:')[1])
    : ticketType.description;
  return (
    <Box
      direction="row"
      gap="medium"
      justify="center"
      align="center"
      pad={{ right: 'large', left: 'small', vertical: 'medium' }}
    >
      <Box width="small" height="xsmall">
        <TicketIcon
          ticketType={ticketType.type}
          price={ticketType.price}
          currency={ticketType.currency ? currencyToText[ticketType.currency] : 'KES'}
        />
      </Box>
      <Box direction="row-responsive" fill="horizontal" gap="small" justify="between">
        <Box fill="horizontal">
          <Text label={ticketType.name} bold medium />
          <Text label={`${ticketType.quantity || 'Unlimited'} Tickets`} small bold />
          {description && <Text small dangerouslySetInnerHTML={{ __html: description }} />}
        </Box>
        <Box direction="row" gap="small" fill="horizontal" justify="end">
          <GButton label="Edit" onClick={onEdit} secondary />
          <Box align="start" justify="center">
            <Tip
              content={`${
                ticketType.status === 'visible' ? 'Hide from the public' : 'Show to the public'
              }`}
            >
              <GButton
                icon={loading ? <Spinner /> : null}
                primary
                label={`${ticketType.status === 'visible' ? 'Hide' : 'Show'}`}
                onClick={onHide}
              />
            </Tip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
TicketTypeRow.defaultProps = {
  onHide: () => {}
};
export default TicketTypeRow;
