import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, Router, useLocation } from '@reach/router';
import { Box, Button as GButton, Heading } from 'grommet';
import tw from 'twin.macro';
import Button from 'components/Button';
import Text from 'components/Text';
import EventHeader from 'components/EventHeader';
import Tabs from 'components/Tabs';
import Col from 'components/Col';
import Row from 'components/Row';
import EmptyState from 'components/EmptyState';
import TicketCreate from 'components/TicketCreate';
import TicketTypeRow from 'components/TicketTypeRow';
import DashboardTab from 'components/DashboardTab';
import { useAuth0 } from '@auth0/auth0-react';
import { decode } from 'js-base64';
import OrderList from 'components/OrderList';
import { isBrowser } from 'utils/browser';
import AttendeeList from 'components/AttendeeList';
import AccordionList from 'components/AccordionList';
import { TicketApi } from 'services/api/ticket-api';
import { useApi } from 'providers/ApiProvider';
import EventAnimation from './EventAnimation';
import Modal from './Modal';
import moment from 'moment';
import { navigate } from 'gatsby';
import { getEvent } from '../services/hasura';

const Content = tw.div`h-full overflow-scroll bg-white`;
const TicketList = tw.div`h-full divide-y-2 divide-gray-200 divide-solid`;
const HelpTabWrapper = tw(Col)`py-28`;

export const HelpTab = ({ event }) => {
  //   const Tawk_API = Tawk_API || {};
  return (
    <HelpTabWrapper centered>
      <Row vCentered centered collapse spacing="sm">
        <Text label="Please send an email to help@tutadoo.com" large bold />
      </Row>
      <Col spacing="sm">
        <Text label="OR" />
      </Col>
      {isBrowser && (
        <Button
          onClick={() => window.Tawk_API && Tawk_API.toggle()}
          label="Leave a message"
          primary
        />
      )}
    </HelpTabWrapper>
  );
};
export const DetailsTab = ({ event }) => {
  const f = ['name', 'category', 'description', 'start_date', 'end_date', 'venue', 'status'].map(
    (key) => {
      let data = event[key];
      if (key === 'description') {
        data = decode(data.split('b64:')[1]);
      }
      return (
        <Row vCentered collapse spacing="sm">
          <Text label={key} medium bold />
          <Text label={`${data}`} />
        </Row>
      );
    }
  );
  return (
    <>
      <Col spacing="sm" spacex>
        {f}
      </Col>
    </>
  );
};
export const TicketsTab = ({ event, reload }: { event: namespace.Event; reload }) => {
  const { api } = useApi();
  const [showModal, setShowModal] = useState(event?.ticket_types?.length === 0);
  const [loading, setLoading] = useState(null);
  const [editTicket, setEditTicket] = useState(null);

  useEffect(() => {
    if (showModal === false) {
      setEditTicket(null);
      reload();
    }
  }, [showModal]);

  useEffect(() => {
    if (editTicket !== null) {
      setShowModal(true);
    }
  }, [editTicket]);

  const hasTickets = (event) => {
    return event.ticket_groups.length > 0 || event.ticket_types.length > 0;
  };

  const onHideTicket = async ({ id, status }) => {
    if (loading) return;
    try {
      const ticketApi = new TicketApi(api);
      let newStatus = status;
      if (status === 'visible') {
        newStatus = 'hidden';
      } else {
        newStatus = 'visible';
      }
      setLoading(id);
      const result = await ticketApi.setStatus(id, newStatus);
      if (result.kind === 'bad-data') {
        alert('Failed: Please try again');
      } else if (result.kind === 'ok') {
        // reload();
        if (event.ticket_types?.length > 0) {
          const objIndex = event.ticket_types.findIndex((obj) => obj.id === id);
          event.ticket_types[objIndex].status = newStatus;
        }
      }
    } catch (error) {
      alert('Please try again');
    }
    setLoading(null);
  };

  return (
    <>
      {!hasTickets(event) && (
        <EmptyState
          onAction={() => {
            setShowModal(true);
          }}
        />
      )}
      {showModal && (
        <Modal
          closeModal={() => {
            setShowModal(false);
          }}
        >
          <TicketCreate
            ticket={editTicket}
            event={event}
            onCancel={() => {
              setShowModal(false);
            }}
          />
        </Modal>
      )}
      {hasTickets(event) && (
        <Box
          margin={{ vertical: 'medium', horizontal: 'large' }}
          justify="between"
          direction="row"
          align="center"
        >
          <Heading level="3" color="purple-800">
            Ticket Types
          </Heading>
          <GButton
            primary
            label="Add Tickets"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </Box>
      )}
      <Box background={'dark-grey-100'} height="1px" fill="horizontal" />
      <TicketList>
        {event.ticket_types?.map((ticketType) => (
          <TicketTypeRow
            key={ticketType.id}
            loading={ticketType.id === loading}
            ticketType={ticketType}
            onEdit={() => setEditTicket(ticketType)}
            onHide={() => onHideTicket(ticketType)}
          />
        ))}
        <AccordionList
          items={event.ticket_groups}
          renderItem={(item) => (
            <Col>
              <TicketTypeRow
                key={item.id}
                ticketType={item}
                loading={ticketType.id === loading}
                onEdit={() => setEditTicket(item)}
                onHide={() => onHideTicket(item)}
              />
            </Col>
          )}
        />
      </TicketList>
    </>
  );
};

export default function EventDashboard() {
  const params = useParams();
  const { pathname } = useLocation();
  const { user } = useAuth0();
  const tp = pathname.replace(`${params.tab}`, '');
  const resp = useQuery(getEvent(params.id, true), {
    // pollInterval: 5000,
    fetchPolicy: 'no-cache'
  });
  if (resp.loading) return <EventAnimation />;
  const event = resp.data?.event;
  if (user.sub !== event.organizer.user_id && !process.env.ADMIN) {
    navigate('/');
    return <EventAnimation />;
  }
  event.orders.forEach((order, i) => {
    event.orders[i].tickets = order.items
      .map(({ name, quantity }) => `${quantity} x ${name}`)
      .join(', ');
    if (event.orders[i].answers) {
      event.orders[i] = { ...event.orders[i].answers, ...event.orders[i] };
    }
    event.orders[i].booking_time = moment(event.orders[i].created_at).format(
      'YYYY-MM-DD  h:MM:SS A'
    );
    event.orders[i].venue = event.venue?.address || event.address;
  });
  event.attendees.forEach((attendee, i) => {
    event.attendees[i].booking_time = moment(event.attendees[i].created_at).format(
      'YYYY-MM-DD  h:MM:SS A'
    );
    event.attendees[i].selected_day = moment(event.attendees[i].day).format('YYYY-MM-DD');
    event.attendees[i].venue = event.venue?.address || event.address;
  });
  return (
    <>
      <Box pad={{ top: 'large', left: 'small', right: 'medium' }}>
        {/* <Text large bold label="Manage Event" spacing="lg" /> */}
        <EventHeader event={event} reload={resp.refetch} isAdmin />
        <Tabs
          items={['Home', 'Ticket Types', 'Bookings', 'Tickets', 'Help']}
          pathname={tp.replace(/\/$/, '')}
        />
        <Content>
          <Router basepath={tp}>
            <DashboardTab path="/home" event={event} user={user} />
            <DashboardTab path="/dashboard" event={event} user={user} />
            <TicketsTab path="/ticket-types" event={event} reload={resp.refetch} />
            {/* <DetailsTab path="details" event={event} /> */}
            <OrderList path="bookings" event={event} />
            <AttendeeList path="/tickets" event={event} />
            <HelpTab path="help" event={event} />
          </Router>
        </Content>
      </Box>
    </>
  );
}
